import React from "react";
import { injectIntl } from "react-intl";
import Cities from "./Cities/index";
import styles from "./styles.css";
import LandingButtonTakeMeThere from "../Landing/LandingButtonTakeMeThere/index";

function LandingGeneratingOrder(props) {

  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.generation.order.${id}` });
  };

  return (
    <div>
      <div className={styles.containerGeneratingOrder}>
        <div className={styles.generatingOrder}>
          <p className={styles.titleGeneration}>
            {translate('title.part.one')}<span className={styles.magenta}>{translate('title.part.two')}</span>{translate('title.part.three')}
          </p>
          <br />
          <div className={styles.contentObjectCitiesMobile}>
            <Cities></Cities>
          </div>
          <p className={styles.paragraph}>{translate('text.one')}</p>
          <p>
            <br />
            {translate('text.two')}
          </p>
          <br />
          <p>{translate('text.three')}</p>
          <br />
          <LandingButtonTakeMeThere text={translate('choose')} href="#header"></LandingButtonTakeMeThere>
        </div>
        <div className={styles.contentObjectCities}>
          <Cities></Cities>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(LandingGeneratingOrder);
