import React from "react";
import HeroLanding from "../HeroLanding/index";
import LandingTheyTrustUs from "./LandingTheyTrustUs/index";
import LandingGeneratingOrder from "../LandingGeneratingOrder/index";
import LandingCenteredContainer from "../../components/LandingCenteredContainer";
import LandingOurSecret from "./LandingOurSecret";
import LandingTecnologyInYourBusiness from "../LandingourTechnologyInYourBusiness/index";
import LandingMagazine from "../LandingMagazine/index";
import LandingStart from "../LandingStart/index";
import FooterLanding from "../../components/FooterLanding/index"

function Landing(props) {
  return (
    <div className={props.subclass}>
      <div>
        <HeroLanding></HeroLanding>
        <LandingCenteredContainer>
          <LandingTheyTrustUs></LandingTheyTrustUs>
          <LandingGeneratingOrder></LandingGeneratingOrder>
          <LandingMagazine></LandingMagazine>
          <LandingOurSecret></LandingOurSecret>
          <LandingStart></LandingStart>
          <LandingTecnologyInYourBusiness></LandingTecnologyInYourBusiness>
        </LandingCenteredContainer>
      </div>
      <FooterLanding
        categoryRawId={props.categoryRawId}
        subcategoryRawId={props.subcategoryRawId}
        destination={props.destination}
        {...props.footerProps}
      />
    </div>

  );
}

export default Landing;
