import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import ModalWindow from "../ModalWindow/ModalWindow";
import MagazineComponent from "./MagazineComponent";
import styles from "./styles.css";
import Link from 'next/link'

function LandingMagazine(props) {

  const desktopLocationRef = useRef();
  const locationModalRef = useRef();

  const modalsRefs = [
    locationModalRef
  ];

  const getElementBounds = (ref) => ref.getBoundingClientRect();

  const isScrollingEvent = (ev) => {
    setIsScrolling(
      document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0
    );
  };

  const magazinesList = [
    {
      name: "Madrid",
      img: "/static/images/LandingNavBar/portada_madrid_final.jpg",
      color: "#343444",
      number: "01",
      colorText: "#000000",
    },
    {
      name: "Barcelona",
      img: "/static/images/LandingNavBar/portada_barcelona_final.jpeg",
      color: "#000000",
      number: "02",
      colorText: "#FFF",
    },
    {
      name: "Mundo",
      img: "/static/images/LandingNavBar/portada_mundo_final.png",
      color: "#CF2FBD",
      number: "03",
      colorText: "#000000",
    },
  ];

  const [magazine, setMagazine] = useState({
    img: "/static/images/LandingNavBar/portada_madrid_final.jpg",
    color: "#343444",
    number: "01",
    name: "Madrid",
  });

  const magazines = magazinesList.map((item, i) => {
    return (
      <option className={styles.option} value={item.name}>
        {item.name}
      </option>
    );
  });

  const getMagazine = (ev) => {
    const magazineValue = magazinesList.find((element) => element.name === ev.target.value);
    setMagazine(magazineValue);
  };

  const translate = (id, values) => {
    return props.intl.formatMessage({ id: `landing.magazine.${id}` }, values );
  };

  let date = new Date();
  let year = date.getFullYear();
  let nexYear = year + 1;

  return (
    <div className={styles.containerMagazine}>
      <div className={styles.contentObjectMagazine}>
        <MagazineComponent
          img={magazine.img}
          color={magazine.color}
          number={magazine.number}
          colorText={magazine.colorText}
        ></MagazineComponent>
      </div>
      <div className={styles.textComponentGuide}>
        <h4 className={styles.title}>
          {translate('title.part.one')}<span className={styles.titlemagenta}>{translate('title.part.two')}</span>{translate('title.part.three')}
        </h4>
        <p>{translate('text.one')}</p>
        <p>{translate('text.two')}</p>
        <br />
        <p>
          {translate('text.three', {
            'year': `${year}`,
            'nextYear': `${nexYear}`
          })}
        </p>
        <p>{translate('text.four')}</p>
        <br />
        <p>{translate('text.five')}</p>
        <div className={styles.contentObjectMagazineMobile}>
          <MagazineComponent
            img={magazine.img}
            color={magazine.color}
            number={magazine.number}
            colorText={magazine.colorText}
          ></MagazineComponent>
        </div>
        <div className={styles.buttonsMagazine}>
          <div>
            <label
              className={styles.labelSelector}
              ref={desktopLocationRef}
              onClick={() => {
                if (!locationModalRef.current.isVisible()) {
                  locationModalRef.current.show({
                    position: getElementBounds(desktopLocationRef.current),
                  });
                  return;
                }
                locationModalRef.current.hide();
              }}
            >
              <p>{translate('choose')}</p>
              <p>{magazine.name}</p>
            </label>
          </div>
          <button className={styles.buy}>{translate('buy')}</button>
          <Link
              href={`/static/files/Guia_discoolver_${magazine.number}.pdf`}
              as={`/static/files/Guia_discoolver_${magazine.number}.pdf`}
              target={'_blank'}
          >
            <a target={'_blank'} className={styles.download}>{translate('download')}</a>
          </Link>
        </div>
      </div>

      <ModalWindow
          title={translate('choose')}
          ref={locationModalRef}
          className={styles.modalDestination}
          classnameContent={styles.modalDestinationContent}
          classnameContainer={styles.modalDestinationContainer}
          autohide={true}
        >
          {magazinesList.map((d) => {
            return (
              <div key={d.number} className={styles.groupItem}>
                <button
                  onClick={() => {
                    setMagazine(d);
                    locationModalRef.current.hide();
                  }}
                  key={d.number}
                  className={classnames([
                    styles.modalItem,
                    magazine.number === d.number
                      ? styles.selected
                      : undefined,
                  ])}
                >
                  <span>{d.name}</span>
                </button>
              </div>
            );
          })}
        </ModalWindow>
    </div>
  );
}

export default injectIntl(LandingMagazine);
