import React from "react";
import Slider from "react-slick";

function LandingSliderComponent(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    ...props.carousellSettings,
  };
  return (
    <div>
      <Slider {...settings}>{props.children}</Slider>
    </div>
  );
}
LandingSliderComponent.defaultProps = {};

export default LandingSliderComponent;
