import React from "react";
import styles from "./styles.css";

function MagazineComponent(props) {
  const divStyle = {
    background: props.color,
  };
  const textColor = {
    color: props.colorText,
  };

  return (
    <div className={styles.container} style={divStyle}>
      <img className={styles.logo} src="/static/images/LandingNavBar/Logo-magenta-y-blanco.png" />
      <p className={styles.number} style={textColor}>
        {props.number}
      </p>
      <img className={styles.image} src={props.img} />
      <div className={styles.guide} style={textColor}>
        GUÍA DISCOOLVER
      </div>
    </div>
  );
}

export default MagazineComponent;
