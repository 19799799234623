import React from "react";
import { injectIntl } from "react-intl";
import Slider from "../../LandingSliderComponent/index";
import styles from "./styles.css";
import LandingButtonTakeMeThere from "../LandingButtonTakeMeThere/index";
import classnames from "classnames";

function LandingOurSecret(props) {
  const imagesPlaces = [
    {
      name: "comercio-local",
      img: "/static/images/LandingNavBar/comercio-local_landing.jpg",
    },
    {
      name: "Cool-places",
      img: "/static/images/LandingNavBar/Cool-places_landing.jpg",
    },
    {
      name: "experiencias",
      img: "/static/images/LandingNavBar/experiencias_laanding.jpg",
    },
    {
      name: "Restaurantes",
      img: "/static/images/LandingNavBar/Restaurantes_landing.jpg",
    },
  ];
  const CoolPlaces = imagesPlaces.map((index) => <img className={styles.carouselPlaces} src={index.img} />);

  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.our.secret.${id}` });
  };

  return (
    <div className={styles.contentOurSecret}>
      <div className={styles.carouselPlaces}>
        <Slider
          carousellSettings={{
            slidesToScroll: 1,
            slidesToShow: 1,
          }}
        >
          {CoolPlaces}
        </Slider>
      </div>
      <div className={styles.contentText}>
        <h4 className={styles.titleOurSecrect}>
          {translate('title.part.one')}<span className={styles.magenta}>{translate('title.part.two')}</span>{translate('title.part.three')}
        </h4>
        <p className={styles.textOurSecrect}>
          {translate('text.one')}
        </p>

        <p className={styles.textOurSecrect}>
          {translate('text.two')}
        </p>

        <p className={classnames(styles.textOurSecrect, styles.textOurSecrectEnd)}>
          {translate('text.three')}
        </p>
        <LandingButtonTakeMeThere text={translate('choose')} href="#header"></LandingButtonTakeMeThere>
      </div>
    </div>
  );
}

export default injectIntl(LandingOurSecret);
