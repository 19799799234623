import React, { Component } from "react";
import styles from "./styles.scss";
import classnames from "classnames";
import Link from "next/link";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, WEB_ROOT } = publicRuntimeConfig;
import { injectIntl } from "react-intl";
import GridContainer from "../GridContainer";
import { withAppState } from "../../contexts/app-state";
import { Router } from "next/router";
import CookieContent from "../CookieContent";
import Column from '../Footer/Column';
import Item from '../Footer/Item';
import Social from '../Footer/Social';

class FooterLanding extends Component {
  state = {
    user: "",
    password: "",
    show_user_sidebar: false,
    tabs: {},
  };
  componentDidMount() {

  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };

  render() {
    return (
      <footer
        className={classnames(
          styles.footer,
          this.props.footerContainerClassName
        )}
      >

        <GridContainer className={this.props.desktopClassName}>
          <div className={styles.container}>
            <div className={classnames(styles.brand, styles[APP])} />
            <div className={styles.options}>
              <Column title={this.translate("footer.title.contact")}>
                <Item
                  label="info@discoolver.com"
                  href="mailto:info@discoolver.com"
                />
                <Item
                  label="Tlf: [+34] 681 291 571"
                  href="tel:+34681291571"
                />
                <Item label="C/ María de Molina 39, 28006 Madrid" />
                <Social
                  facebook={this.props.destination.facebook}
                  twitter={this.props.destination.twitter}
                  instagram={this.props.destination.instagram}
                  linkedin={this.props.destination.linkedin}
                />
              </Column>
              <Column className={styles.contentUs} title={this.translate("footer.title.us")}>
                <Item
                  href={`/about?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/about`}
                  label={this.translate("footer.link.about")}
                />
                <Item
                  href={`/how-to-join?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/how-to-join`}
                  label={this.translate("footer.link.how-to-join")}
                />
                <Item
                  href={`/term?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/term`}
                  label={this.translate("footer.link.term")}
                />
                <Item
                  href={`/policy?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/policy`}
                  label={this.translate("footer.link.policy")}
                />
                <Item
                  href={`/legal/warning?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/legal/warning`}
                  label={this.translate("footer.link.legal.warning")}
                />
                <Item
                  href={`/cookie?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/cookie`}
                  label={this.translate("footer.link.cookie")}
                />
                <div className={styles.column}>
                  <a
                    className={styles.item}
                    href="https://blog.discoolver.com/"
                  >
                    {this.translate("footer.link.blog")}
                  </a>
                </div>
              </Column>
              <Column className={styles.contentCategory} title={this.translate("footer.title.category")}>
                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=alojamiento`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/alojamiento`}
                  label={this.translate("categories.accommodations")}
                />
                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=restaurantes`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/restaurantes`}
                  label={this.translate("categories.restaurants")}
                />
                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=que_ver`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/que_ver`}
                  label={this.translate("categories.to_see")}
                />
                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=fiesta`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/fiesta`}
                  label={this.translate("categories.party")}
                />
                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=ocio`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/ocio`}
                  label={this.translate("categories.leisure")}
                />
                <Item
                  href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&categoryRawId=compras`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/compras`}
                  label={this.translate("categories.shop")}
                />
              </Column>
              <Column className={styles.contentPlan} title={this.translate("footer.title.plan")}>
                <Item
                  href={`/plan-my-trip?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/plan-my-trip`}
                  label={this.translate("footer.link.plan-my-trip")}
                />
                <Item
                  href={`/calendar?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/calendar`}
                  label={this.translate("footer.link.calendar")}
                />
                <Item
                  href={`/map?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                  as={`/${this.props.intl.locale}/${this.props.destination.rawId}/map`}
                  label={this.translate("footer.link.map")}
                />
              </Column>
            </div>
          </div>
        </GridContainer>

        {this.state.show_user_sidebar && this.getUserSidebar()}
        <CookieContent destination={this.props.destination} />
      </footer>
    );
  }
}

FooterLanding.defaultProps = {
  city: {},
  user: {},
};

export default injectIntl(withAppState(FooterLanding));
