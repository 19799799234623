import React from "react";
import { injectIntl } from "react-intl";
import ComponentStart from "./ComponentStart/index";
import styles from "./styles.css";
import classNames from "classnames";
import LandingButtonTakeMeThere from "../Landing/LandingButtonTakeMeThere";

function LandingStart(props) {
  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.start.${id}` });
  };
  return (
    <>
      <div className={styles.containerlandingStar}>
        <div className={styles.star}>
          <h4 className={styles.startTitle}>
            {translate('title.part.one')}<span className={styles.magenta}>{translate('title.part.two')}</span>
          </h4>
          <p className={styles.subTitle}>{translate('text.one')}</p>

          <p className={styles.subTitle}>
            {translate('text.two')}
          </p>
          <p className={classNames(styles.subTitle, styles.subTitleEnd)}>
            {translate('text.three')}
          </p>
          <ComponentStart>
            <div className={styles.conteinerText}>
              <h6 className={styles.titleSectionsLine}>{translate('text.four')}</h6>
              <p className={styles.paragraphSectionsLine}>
                {translate('text.five')}
              </p>
            </div>
          </ComponentStart>
          <ComponentStart>
            <div className={styles.conteinerText}>
              <h6 className={styles.titleSectionsLine}>{translate('text.six')}</h6>
              <p className={styles.paragraphSectionsLine}>
                {translate('text.seven')}
              </p>
            </div>
          </ComponentStart>
          <ComponentStart>
            <div className={styles.conteinerText}>
              <h6 className={styles.titleSectionsLine}>{translate('text.height')}</h6>
              <p className={styles.paragraphSectionsLine}>
                {translate('text.nine')}
              </p>
            </div>
          </ComponentStart>
          <ComponentStart>
            <div className={styles.conteinerText}>
              <h6 className={styles.titleSectionsLine}>{translate('text.ten')}</h6>
              <p className={styles.paragraphSectionsLine}>
                {translate('text.eleven')}
              </p>
            </div>
          </ComponentStart>
          <ComponentStart background="#ed32be" check="#fbd4f1" lineEnd="none">
            <div className={styles.conteinerText}>
              <h6 className={styles.titleSectionsLine}>{translate('text.twelve')}</h6>
              <p className={styles.paragraphSectionsLine}>
                {translate('text.thirteen')}
              </p>
            </div>
          </ComponentStart>
        </div>
        <div className={styles.contentMovilImage}>
          <img
            className={styles.movilImage}
            src="/static/images/LandingNavBar/iPhone-12 plan my trip categorias.png"
          />
        </div>
      </div>
      <LandingButtonTakeMeThere
        text={translate('plan')}
        width="255px"
        href="https://discoolver.com/es/madrid/plan-my-trip"
      ></LandingButtonTakeMeThere>
    </>
  );
}

export default injectIntl(LandingStart);
