import React from "react";
import { injectIntl } from "react-intl";
import DestinationSelector from "../HeroLanding/ComponentHeroLanding/DestinationSelector";
import styles from "./styled.css";

const ContentOfTheLanding = (props) => {

  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.content.of.landing.${id}` });
  };

  return (
    <div className={styles.container}>
      <div className={styles.heroContainerTitle}>
        <h2 className={styles.heroTitle}>{translate('heroTitle')}</h2>
        <p className={styles.heroSubTitle}>{translate('heroSubTitle')}</p>
        <DestinationSelector></DestinationSelector>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonApp}>
            <img className={styles.buttonApp} src="static/images/LandingNavBar/App-Store.png" alt="movil" />
          </div>
          <div className={styles.buttonGooglePlay}>
            <img
              className={styles.buttonGooglePlay}
              src="/static/images/LandingNavBar/en_badge_web_generic.png"
              alt="movil"
            />
          </div>
        </div>
      </div>
      <img className={styles.phoneContainer} src="/static/images/LandingNavBar/mobile-hero.png" alt="movil" />
    </div>
  );
}

export default injectIntl(ContentOfTheLanding);
