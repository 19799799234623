import React from "react";
import styles from "./styles.css";

function LandingButtonTakeMeThere(props) {
  const width = {
    width: props.width,
  };

  return (
    <div className={styles.contentButtonGo}>
      <a className={styles.buttonGo} style={width} href={props.href}>
        <div className={styles.buttonGo} style={width}>
          {props.text}
        </div>
      </a>
    </div>
  );
}

LandingButtonTakeMeThere.propTypes = {};

export default LandingButtonTakeMeThere;
