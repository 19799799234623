import React from "react";
import { injectIntl } from "react-intl";
import styles from "./styled.css";
import LandingSliderComponent from "../../LandingSliderComponent/index";
import classnames from "classnames";

function LandingTheyTrustUs(props) {
  const listTheyTrustUs = [
    { name: "Destino turístico inteligente", img: "/static/images/LandingNavBar/companies/Grafico_pegado_2.png", id: 2 },
    { name: "Seguitur", img: "/static/images/LandingNavBar/companies/Grafico_pegado_3.png", id: 3 },
    //{ name: "Icex", img: "/static/images/LandingNavBar/companies/Grafico_pegado_4.png", id: 4 },
    { name: "Vocces", img: "/static/images/LandingNavBar/companies/Grafico_pegado_5.png", id: 5 },
    { name: "Costa del sol - TourisM HUB", img: "/static/images/LandingNavBar/companies/Grafico_pegado_6.png", id: 6 },
    { name: "Tetuán Valley", img: "/static/images/LandingNavBar/companies/Grafico_pegado_7.png", id: 7 },
  ];

  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.they.trust.us.${id}` });
  };

  const newListTheyTrustUs = listTheyTrustUs.map((item) => {
    return (
      <li
        key={item.id}
        className={styles.trustUsItem}
      >
        <div style={{
          backgroundImage: `url(${item.img})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          width: '95%',
          height: '95%'
        }}>
          &nbsp;
        </div>
      </li>
    );
  });

  return (
    <div className={styles.containerTrustUs}>
      <h3 className={classnames(styles.titles, styles.titlesTrusUs)}>{translate('title')}</h3>
      <p className={classnames(styles.subtitlesTrusUs, styles.titlesTrusUs)}>{translate('subtitle')}</p>
      <LandingSliderComponent
        carousellSettings={{
          dots: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                centerMode: false,
                slidesToShow: 5,
                slidesToScroll: 5
              }
            },
            {
              breakpoint: 768,
              settings: {
                centerMode: false,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }}
      >
        {newListTheyTrustUs}
      </LandingSliderComponent>
    </div>
  );
}

LandingTheyTrustUs.propTypes = {};

export default injectIntl(LandingTheyTrustUs);
