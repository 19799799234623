import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import ModalWindow from "../../ModalWindow/ModalWindow";
import styles from "./styled.css";
import { getDestinations } from "../../../api/index";

function DestinationSelector(props) {
  const [destinationList, setDestinationList] = useState([]);
  const [destination, setDestination] = useState({id: 'madrid', name: 'Madrid'});
  const [url, setUrl] = useState("https://discoolver.com/es/madrid");

  const desktopLocationRef = useRef();
  const locationModalRef = useRef();

  const modalsRefs = [
    locationModalRef
  ];

  const getURL = (ev) => {
    let id = ev.target.value;
    console.log("id", id);
    setUrl(`https://discoolver.com/es/${id}`);
  };

  const getElementBounds = (ref) => ref.getBoundingClientRect();

  const isScrollingEvent = (ev) => {
    setIsScrolling(
      document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDestinations("es");

      const mappedDestinationList = mapDestinationsToSelectData(response);

      setDestinationList(mappedDestinationList);
    };
    fetchData();
  }, []);

  const mapDestinationsToSelectData = (list) => {
    const destinationList = [];

    list.forEach((country) => {
      destinationList.push({ id: country.rawId, name: country.name, isCountry: true });

      country.destinations.forEach((city) => {
        destinationList.push({ id: city.rawId, name: city.name, isCountry: false });
      });
    });

    return destinationList;
  };

  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.destination.selector.${id}` });
  };

  return (
    <div>
      <div className={styles.destination}>
        <div className={styles.destinationSelector}>
          <label
            ref={desktopLocationRef}
            onClick={() => {
              if (!locationModalRef.current.isVisible()) {
                locationModalRef.current.show({
                  position: getElementBounds(desktopLocationRef.current),
                });
                return;
              }
              locationModalRef.current.hide();
            }}
          >
            <p>{translate('select')}</p>
            <p>{ destination.name || ''}</p>
          </label>
        </div>
        <a href={url} className={styles.contentLlevameAhi}>
          <div className={styles.llevameAhi}>{translate('go')}</div>
        </a>
      </div>
      <ModalWindow
          title={translate('select')}
          ref={locationModalRef}
          className={styles.modalDestination}
          classnameContent={styles.modalDestinationContent}
          classnameContainer={styles.modalDestinationContainer}
          autohide={true}
        >
          {destinationList.map((d) => {
            if(d.isCountry){
              return undefined
            }
            return (
              <div key={d.id} className={styles.groupItem}>
                <button
                  onClick={() => {
                    setDestination(d);
                    if(d.id === 'ronda'){
                      setUrl(`https://turismoderonda.es`)
                    }else{
                      setUrl(`https://discoolver.com/es/${d.id}`)
                    }
                    locationModalRef.current.hide();
                  }}
                  key={d.id}
                  className={classnames([
                    styles.modalItem,
                    destination.id === d.id
                      ? styles.selected
                      : undefined,
                  ])}
                >
                  <span>{d.name}</span>
                </button>
              </div>
            );
          })}
        </ModalWindow>
    </div>
  );
}

export default injectIntl(DestinationSelector);
