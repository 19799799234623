import React from "react";
import LandingNavBar from "../LandingNavBar/index";
import ContentOfTheLanding from "../ContentOfTheLanding/index";
import LandingCenteredContainer from "../../components/LandingCenteredContainer";

import styles from "./styled.css";
function index(props) {
  return (
    <div className={styles.heroContainer}>
      <LandingCenteredContainer>
        <LandingNavBar></LandingNavBar>
        <ContentOfTheLanding></ContentOfTheLanding>
      </LandingCenteredContainer>
    </div>
  );
}

export default index;
