import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { injectIntl } from "react-intl";
import ModalWindow from "../ModalWindow/ModalWindow";
import styles from "./styles.css";
import Head from "next/head";

const languageItems = [
  { id: 'es', name: 'ES' },
  { id: 'en', name: 'EN' },
  { id: 'fr', name: 'FR' },
  { id: 'de', name: 'DE' },
  { id: 'pt', name: 'PT' },
  { id: 'it', name: 'IT' }
]

const LandingNavBar = (props) => {

  const [showMenu, setShowMenu] = useState(false);
  const [language, getLanguage] = useState({});
  const router = useRouter();

  const desktopLanguageRef = useRef();
  const desktopLanguageMobileRef = useRef();
  const languageModalRef = useRef();

  const getElementBounds = (ref) => ref.getBoundingClientRect();

  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.navbar.${id}` });
  };

  const onChangeLang = (lang) => {
    let url_path = router.asPath.split('/')
    url_path[1] = lang
    router.push(url_path.join('/'))
  }

  const goTo = (url) => {
    router.push(url);
  }

  return (
    <>
      <Head>
        <link rel="stylesheet" href="/static/css/_clear.css" />
        <link rel="stylesheet" href="/static/css/_index.css" />
      </Head>
      <header id="header">
        <nav class="nav" id="navbar">
          <div class="content-nav">
            <img class="content-logo-nav" src="/static/images/index/logo-discoolver.svg" />
            <button class="home-burger" onClick={() => {
              setShowMenu(true);
            }}>&nbsp;</button>
            <div class={`content-all-links${showMenu ? ' show' : ''}`}>
              <div class="header-menu-show">
                <img class="content-logo-nav" src="/static/images/index/logo-discoolver.svg" />
                <button class="home-close-burger" onClick={() => {
                  setShowMenu(false);
                }}>&nbsp;</button>
              </div>
              <div class="nav-links">
                <div class="content-nav-link checked">
                  <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}`)}>
                    <label class="nav-link-text">Home</label>
                  </button>
                </div>
                <div class="content-nav-link">
                  <button class="nav-link" onClick={() => goTo('https://blog.discoolver.com/')}>
                    <label class="nav-link-text">Blog</label>
                  </button>
                </div>
                <div class="content-nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/influencers`)}>
                  <button class="nav-link">
                    <label class="nav-link-text">Influencers</label>
                  </button>
                </div>
                <div class="content-nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/360`)}>
                  <button class="nav-link">
                    <label class="nav-link-text">360º</label>
                  </button>
                </div>
              </div>
              <div class="nav-links-access">
                <div class="content-nav-link">
                  <button class="nav-link"
                    ref={desktopLanguageRef}
                    onClick={() => {
                      if (!languageModalRef.current.isVisible()) {
                        languageModalRef.current.show({
                          position: getElementBounds(desktopLanguageRef.current),
                        });
                        return;
                      }
                      languageModalRef.current.hide();
                    }}
                  >
                    <span class="language" />
                    <label class="nav-link-text">{props.intl.locale}</label>
                  </button>
                </div>
                  <div class="content-nav-link">
                      <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/madrid/register`)}>
                          <label class="nav-link-text">Sing up</label>
                      </button>
                  </div>
                  <div class="content-nav-link checked">
                      <button class="nav-link" onClick={() => goTo(`https://discoolver.com/${props.intl.locale}/madrid/signin`)}>
                          <label class="nav-link-text">Login</label>
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </nav>
        {/*
        <nav className={classnames(styles.landingNav, {[styles.showMenu]: showMenu})}>

          <img
            className={styles.logo}
            src="/static/images/LandingNavBar/Logo-magenta-y-blanco.png"
            alt="discoolver icom"
          />

          <button
            className={styles.buttonBurger}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          >
            &nbsp;
          </button>

          <ul className={styles.ulNavBar}>
          <a className={styles.buttonClose}
              onClick={() => {
                setShowMenu(false);
              }}>

            </a>
            <a className={styles.linkNav}
              onClick={() => {
                setShowMenu(false);
              }}
              href="#" target="_blank">
              <li className={styles.homeLink}>{translate('home')}</li>
            </a>

            <a className={styles.linkNav}
              onClick={() => {
                setShowMenu(false);
              }}
              href="https://discoolver.com/es/madrid/about" target="_blank">
              <li>{translate('about.us')}</li>
            </a>

            <a className={styles.linkNav}
              onClick={() => {
                setShowMenu(false);
              }}
              href="https://blog.discoolver.com/" target="_blank">
              <li>{translate('blog')}</li>
            </a>

            <a
              className={styles.linkNav}
              onClick={() => {
                setShowMenu(false);
              }}
              href="https://discoolver.com/es/madrid/how-to-join"
              target="_blank"
            >
              <li>{translate('how.to.join')}</li>
            </a>

            <a
              ref={desktopLanguageRef}
              className={classnames(styles.linkNav, styles._768, styles.language)}
              onClick={() => {
                if (!languageModalRef.current.isVisible()) {
                  languageModalRef.current.show({
                    position: getElementBounds(desktopLanguageRef.current),
                  });
                  return;
                }
                languageModalRef.current.hide();
              }}
            >
              <span>
                {props.intl.locale}
              </span>
            </a>

            <a
              ref={desktopLanguageMobileRef}
              className={classnames(styles.linkNav, styles.mobile, styles.language)}
              onClick={() => {
                if (!languageModalRef.current.isVisible()) {
                  languageModalRef.current.show({
                    position: getElementBounds(desktopLanguageMobileRef.current),
                  });
                  return;
                }
                languageModalRef.current.hide();
              }}
            >
              <span>
                {props.intl.locale}
              </span>
            </a>
          </ul>
        </nav>
        */}
        <ModalWindow
          title={''}
          ref={languageModalRef}
        >
          {languageItems.map((d) => {
            return (
              <button
                onClick={() => {
                  onChangeLang(d.id)
                  languageModalRef.current.hide();
                }}
                key={d.id}
                className={classnames([
                  styles.modalItem,
                  props.intl.locale === d.id ? styles.selected : undefined,
                ])}
              >
                <span>
                  {props.intl.formatMessage({
                    id: d.name,
                  })}
                </span>
              </button>
            );
          })}
        </ModalWindow>
      </header>
    </>
  );
}

export default injectIntl(LandingNavBar);
