import React from "react";
import { injectIntl } from "react-intl";
import Slider from "../LandingSliderComponent/index";
import styles from "./styles.css";
import classNames from "classnames";
import LandingButtonTakeMeThere from "../Landing/LandingButtonTakeMeThere/index";

function LandingTecnologyInYourBusiness(props) {
  const B2B = [
    {
      name: "mockup_desk",
      img: "/static/images/LandingNavBar/mockup_desk_discoolver.jpg",
    },
    {
      name: "Mockup",
      img: "/static/images/LandingNavBar/Mockup_Discoolver_desk.jpg",
    },
    {
      name: "mockup_hotel",
      img: "/static/images/LandingNavBar/mockup_hotel.jpg",
    },
    {
      name: "mockup_ronda",
      img: "/static/images/LandingNavBar/mockup_ronda_discoolver.jpg",
    },
    {
      name: "ronda_destinos",
      img: "/static/images/LandingNavBar/ronda_destinos.jpg",
    },
  ];
  const imageB2B = B2B.map((index) => <img className={styles.carouselPlaces} src={index.img} />);
  const translate = (id) => {
    return props.intl.formatMessage({ id: `landing.technology.${id}` });
  };
  return (
    <div className={styles.contentTecnology}>
      <div className={styles.contentText}>
        <h4 className={styles.titleTecnology}>
          {translate('title.part.one')}<span className={styles.magenta}>{translate('title.part.two')}</span>{translate('title.part.three')}
        </h4>
        <p className={styles.textTecnology}>{translate('text.one')}</p>
        <p className={styles.textTecnology}>{translate('text.two')}</p>
        <br />
        <p className={styles.textTecnology}>{translate('text.three')}</p>
        <p className={styles.textTecnology}>{translate('text.four')}</p>
        <p className={classNames(styles.textTecnology, styles.textTecnologyEnd)}>
          {translate('text.five')}
        </p>
        <LandingButtonTakeMeThere
          text={translate('contact')}
          width="255px"
          href="https://discoolver.com/es/madrid/how-to-join"
        ></LandingButtonTakeMeThere>
      </div>
      <div className={styles.carouselB2B}>
        <Slider
          carousellSettings={{
            slidesToScroll: 1,
            slidesToShow: 1,
          }}
        >
          {imageB2B}
        </Slider>
      </div>
    </div>
  );
}

export default injectIntl(LandingTecnologyInYourBusiness);
