import React from "react";
import styles from "./styles.css";

function Componentlanding(props) {
  const divStyle = {
    background: props.background,
    color: props.check,
  };

  const lineStyle = {
    display: props.lineEnd,
  };

  return (
    <div className={styles.conteinerTimeLine}>
      <div className={styles.circle} style={divStyle}>
        ✓
      </div>
      <div className={styles.line} style={lineStyle}></div>
      {props.children}
    </div>
  );
}

export default Componentlanding;
