import React from "react";
import Slider from "../../LandingSliderComponent/index";
import styles from "./styles.css";

function Cities(props) {
  const cities = [
    {
      name: "Málaga",
      img: "/static/images/LandingNavBar/MALAGA.png",
    },
    {
      name: "Madrid",
      img: "/static/images/LandingNavBar/MADRID.png",
    },
    {
      name: "Ronda",
      img: "/static/images/LandingNavBar/RONDA.png",
    },
    {
      name: "Republica Dominicana",
      img: "/static/images/LandingNavBar/REP.DOM.png",
    },
    {
      name: "Barcelona",
      img: "/static/images/LandingNavBar/BARCELONA.png",
    },
    {
      name: "Aranjuez",
      img: "/static/images/LandingNavBar/ARANJUEZ.png",
    },
  ];

  const city = cities.map((index) => <img src={index.img} />);
  return (
    <div className={styles.carouselCities}>
      <Slider
        carousellSettings={{
          slidesToScroll: 1,
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          dots: false,
        }}
      >
        {city}
      </Slider>
    </div>
  );
}

export default Cities;
